/* AdminSection.css */

.admin-section-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .admin-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .admin-section-header h1 {
    margin: 0;
  }
  
  .logout-button {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
  }
  
  .admin-section-error {
    color: red;
    margin-bottom: 10px;
  }
  
  .admin-section-form,
  .admin-section-edit-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .admin-section-form h2,
  .admin-section-edit-form h3 {
    margin-top: 0;
  }
  
  .admin-section-form.edit-form {
    background-color: #e6f7ff;
    border: 2px solid #1890ff;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    outline: none;
    border-color: #1890ff;
  }
  
  .form-group .react-datepicker-wrapper {
    width: 100%;
  }
  
  .form-group .react-datepicker-wrapper input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .form-actions {
    display: flex;
    gap: 10px;
  }
  
  .admin-section-form button,
  .admin-section-edit-form button {
    background-color: #1890ff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-section-form button:hover,
  .admin-section-edit-form button:hover {
    background-color: #1070c0;
  }
  
  .cancel-button {
    background-color: #f44336;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
  
  .calendar-container {
    margin-bottom: 30px;
  }
  
  .entries-calendar {
    width: 100%;
    border: none;
    font-family: 'Arial', sans-serif;
  }
  
  .calendar-entry-dot {
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border-radius: 50%;
    margin: 2px auto;
  }
  
  .admin-section-entry-list {
    list-style-type: none;
    padding: 0;
  }
  
  .admin-section-entry-list li {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .admin-section-entry-list li .entry-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .admin-section-entry-list li .entry-details div {
    margin-bottom: 5px;
  }
  
  .admin-section-entry-list li .entry-actions {
    margin-top: 15px;
  }
  
  .admin-section-entry-list li .entry-actions button {
    margin-right: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-section-entry-list li .entry-actions button:first-of-type {
    background-color: #4CAF50;
    color: white;
  }
  
  .admin-section-entry-list li .entry-actions button:first-of-type:hover {
    background-color: #43a047;
  }
  
  .admin-section-entry-list li .entry-actions button:last-of-type {
    background-color: #f44336;
    color: white;
  }
  
  .admin-section-entry-list li .entry-actions button:last-of-type:hover {
    background-color: #d32f2f;
  }
  
  .amount {
    font-weight: bold;
  }
  
  .amount.positive {
    color: green;
  }
  
  .amount.negative {
    color: red;
  }
  
  .spinner-small {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
  }
  
  @media (max-width: 600px) {
    .modal-content {
      width: 90%;
    }
  
    .admin-section-entry-list li .entry-details {
      grid-template-columns: 1fr;
    }
  }
  