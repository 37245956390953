@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f7fa;
    margin: 0;
    padding: 0;
    color: #333;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-header {
    background-color: #2c3e50;
    padding: 15px 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.app-header h1 {
    margin: 0;
    font-size: 1.8rem;
    color: white;
}

.app-header nav {
    display: flex;
    gap: 20px;
}

.app-header nav a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.app-header nav a:hover,
.app-header nav a.active {
    background-color: #34495e;
}

.app-main {
    flex: 1;
    margin-top: 80px;
    padding: 20px;
}

.dashboard {
    display: flex;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.left-container {
    flex: 1;
    max-width: 300px;
}

.right-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* New Section Above BalanceGraph */
.custom-section {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;

}

.custom-box {
    background-color: white;
    border-radius: 8px;
    padding: 40px; /* Adjust the padding for consistency */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 25px; /* Set a width similar to stat cards */
    height: 25px; /* Set the height to match width */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center; /* Center the text */
}

.custom-box h2 {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #7f8c8d;
}

.custom-box .stat-value {
    font-size: 1.8rem;
    font-weight: 700;
}

/* Green color for Total Calls */
.custom-box.calls .stat-value {
    color: #2ecc71; /* Green */
}

/* Red color for Total Puts */
.custom-box.puts .stat-value {
    color: #e74c3c; /* Red */
}

.custom-box span {
    font-size: 1.8rem; /* Font size for the numbers */
    font-weight: 700;
}

.custom-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Ensure the custom section spreads evenly */
.custom-section .custom-box {
    flex-grow: 1;
    max-width: 120px; /* Max width similar to left boxes if needed */
}

/* Stat Card Section */
.stat-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.stat-card h2 {
    margin: 0 0 10px 0;
    font-size: 1rem;
    color: #7f8c8d;
}

.stat-value {
    font-size: 1.8rem;
    font-weight: 700;
}

.positive {
    color: #2ecc71;
}

.negative {
    color: #e74c3c;
}

.calendar-container {
    flex: 1;
}

.balance-graph {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.balance-graph h2 {
    margin: 0 0 15px 0;
    font-size: 1.2rem;
    color: #2c3e50;
}

/* Additional CSS for other elements */

.react-calendar {
    width: 100%;
    border: none;
    font-family: 'Roboto', sans-serif;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.react-calendar__tile {
    padding: 10px;
    font-size: 0.9rem;
}

.react-calendar__tile--now {
    background-color: #597d96;
    color: white;
}

.react-calendar__tile--active {
    background-color: #2980b9;
    color: white;
}

.react-calendar__navigation {
    margin-bottom: 10px;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    color: #2c3e50;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__days__day--weekend {
    color: #e74c3c;
}

.full-page-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.full-page-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.goal-progress-container {
    text-align: center;
    margin: 20px 0;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: #3e98c7;
    dominant-baseline: middle;
    text-anchor: middle;
    font-size: 25px;
    font-weight: bold; /* Add bold styling to match the stat-value if it uses bold */
  }
  
  .progress-wheel-wrapper {
    width: 55px;  /* Adjust the size of the progress wheel */
    height: 55px; /* Adjust the size of the progress wheel */
    margin: 0 auto;  /* Center the progress wheel */
  }
  
  .goal-text {
    margin-top: 10px;
    font-size: 1rem;
  }
  
  .goal-text p {
    color: #7f8c8d; /* Match the color to the other stat values */
    margin: 5px 0; /* Update the margin to be consistent with the other stat values */
    font-weight: bold; /* Add bold styling to match the stat-value if it uses bold */
  }
  

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .dashboard {
        margin-top: 30px;
        flex-direction: column;
    }

    .left-container {
        max-width: 100%;
    }

    .header-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .app-header nav {
        margin-top: 10px;
    }

    .custom-box {
        margin-top: -30px;
        padding: 20px; /* Reduce padding for mobile view */
        width: 100px; /* Adjust the width to fit on mobile */
        height: auto; /* Allow the height to auto adjust */
        font-size: 0.9rem; /* Reduce font size slightly */
    }

    .custom-box span, .custom-box .stat-value {
        font-size: 1.4rem; /* Adjust the stat-value font size for smaller screens */
    }

    .goal-progress-container {
        margin: 15px 0;
    }

    .progress-wheel-wrapper {
        width: 60px; /* Reduce the size of the progress wheel */
        height: 40px; /* Match the reduced size */
    }

    .goal-text p {
        font-size: 0.9rem; /* Adjust the goal text size */
    }
}
