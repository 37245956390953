.login-container {
    max-width: 320px;
    margin: 100px auto;
    padding: 30px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    text-align: center;
    margin-bottom: 24px;
    color: #2c3e50;
    font-size: 24px;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container .form-group {
    margin-bottom: 16px;
  }
  
  .login-container label {
    display: block;
    margin-bottom: 6px;
    color: #34495e;
    font-weight: bold;
    font-size: 14px;
  }
  
  .login-container input {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .login-container input:focus {
    outline: none;
    border-color: #3498db;
  }
  
  .login-container button {
    padding: 10px;
    background-color: #3498db;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 8px;
  }
  
  .login-container button:hover:not(:disabled) {
    background-color: #2980b9;
  }
  
  .login-container button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  
  .error {
    color: #e74c3c;
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }