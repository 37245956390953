.calendar-container {
    position: relative;
    margin-top: -10px;
}

.react-calendar {
    width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.react-calendar__tile {
    padding: 10px;
    font-size: 0.9rem;
}

.react-calendar__tile--now {
    background-color: #e8f4fd;
    color: #2980b9;
}

.react-calendar__tile--active {
    background-color: #32688b;
    color: white;
}

.react-calendar__tile .positive,
.react-calendar__tile .negative {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 5px;
}

.positive {
    color: #00ff6a;
}

.negative {
    color: #e74c3c;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-top: 0;
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
}

.trades-list {
    margin-bottom: 20px;
}

.trade-item {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 15px;
}

.trade-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.stock-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #34495e;
}

.trade-amount {
    font-size: 1.1rem;
    font-weight: bold;
}

.trade-details p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #7f8c8d;
}

.total-profit-loss {
    background-color: #ecf0f1;
    border-radius: 6px;
    padding: 15px;
    text-align: center;
}

.total-profit-loss h3 {
    margin-top: 0;
    color: #2c3e50;
}

.total-profit-loss .amount {
    font-size: 1.5rem;
    font-weight: bold;
}

.close-modal {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.close-modal:hover {
    background-color: #2980b9;
}

@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        padding: 15px;
    }

    .trade-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .trade-amount {
        margin-top: 5px;
    }
}

.react-calendar__navigation {
    margin-top: 30px; /* Adjust as needed */
    position: relative; /* Keep this to make sure positioning of other elements works well */
}

/* Force hover styles */
.react-calendar__tile:hover {
    background-color: #f0f4f8 !important; /* Light grey-blue */
    color: #333 !important;
}

.react-calendar__tile--now:hover {
    background-color: #c7e2fd !important; /* Lighter blue for current day */
    color: #2980b9 !important;
}

.react-calendar__tile--active:hover {
    background-color: #2980b9 !important; /* Same as active, or slightly darker */
    color: white !important;
}

/* Ensure other states are also explicitly set */
.react-calendar__tile--now {
    background-color: #e8f4fd !important;
    color: #2980b9 !important;
}

.react-calendar__tile--active {
    background-color: #32688b !important;
    color: white !important;
}

/* Additional override for any potential conflicting styles */
.react-calendar__month-view__days__day:hover {
    background-color: #f0f4f8 !important;
    color: #333 !important;
}

/* Styles for the toggle switch positioned inside the calendar container */
.toggle-switch-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.switch-label {
    margin-right: 10px;
    font-size: 0.9rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #3498db;
}

input:checked + .slider:before {
    transform: translateX(18px);
}
